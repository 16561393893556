<template>

    <div class="hero">
        <div class="image-box">
            <img class="bullet" src="../assets/gold-bullet-1.png">
            <img src="../assets/gold-bullet-1-90.png" alt="" class="bullet90">
        </div>
        <div class="text-box">
            <h1>9mm Gold Bullet</h1>
            <p>Solid Gold 9mm Bullet. A fully functional round crafted from pure gold, blending luxury with precision.
                The exquisite piece is designed for collectors and enthusiasts who appreciate the ultimate fusion of 
                craftsmanship and performance.</p>
            <h3>$1,500</h3>
            <h4>.5 Troy Ounce, .9999 Gold</h4>
        </div>
    </div>
    <a href="mailto:carson@dopa.us">
    <div class="contact">
        <div class="button">
            <p>Email to Order</p>
        </div>
    </div>
    </a>







</template>







<script>
export default {
  name: 'HomeHero',
  props: {
    
  }
}
</script>