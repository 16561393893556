<template>
    <div class="container dark">
        <div class="footer">
            <p>COPYRIGHT © 2024 LUCKEY ARMS LLC</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterView',
}
</script>