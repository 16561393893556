<template>
  <NavBar />
  <HomeHero />
  <FooterView />
</template>

<style src="@/css/main.scss" lang="scss" />

<script>
import FooterView from '@/components/FooterView.vue';
import NavBar from '@/components/NavBar.vue';
import HomeHero from '@/components/HomeHero.vue';

export default {
  components: {
    FooterView,
    NavBar,
    HomeHero,
  }
}
</script>